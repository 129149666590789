import React, {useCallback, useEffect, useRef, useState, Fragment} from 'react';
import styled from 'styled-components';
import {pricingTypes} from '../pricingTypes';
import SpecForm from './ProductSpecEditor/SpecForm';
import {generate} from '../Utils/SpecUtils';

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

let pricingTypeOptions = pricingTypes
  .filter((x) => x !== 'custom')
  .map((x) => ({
    label: x,
    value: x,
  }));

export default function Editor({}) {
  const [pricing_type, setPricingType] = useState(
    'common' || pricingTypeOptions[0].value,
  );

  return (
    <Wrapper>
      <SpecForm
        type={pricing_type}
        data={generate({type: pricing_type})}
        // data={mock}
        onEdit={(data) => {
          console.log('onEdit', data);
          window.navigator.clipboard
            .writeText(JSON.stringify(data, null, 2))
            .then(
              () => {
                alert('已成功複製到剪貼簿');
              },
              () => {
                alert('發生錯誤');
              },
            );
        }}
        onMount={() => {}}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #bbb;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 30px;
  min-height: 1000px;

  & > .features {
    padding: 10px 20px;
  }

  & > .back-button {
    margin: 20px;
    color: #000;
    cursor: pointer;
  }

  & .filter-row {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    & > .label {
      margin-right: 10px;
      width: 120px;
    }
  }
`;
